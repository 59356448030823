import React from "react"
import GcButton from "../components/GcButton"

export default class GcCounter extends React.Component {
  static defaultProps = {
    initialvalue: 0,
  }

  state = {
    value: Number(this.props.initialvalue),
  }

  handleIncrement = () => {
    this.setState(state => {
      return {
        value: state.value + 1,
      }
    })
  }

  handleDecrement = () => {
    this.setState(state => {
      return {
        value: state.value - 1,
      }
    })
  }

  render() {
    return (
      <div
        className={`flex flex-row items-center flex-auto w-full md:w-1/2 md:mx-auto bg-gray-600 rounded`}
      >
        <GcButton
          className={`flex-0 px-6 bg-red-600 hover:bg-red-700 text-white`}
          onClick={this.handleDecrement}
        >
          -1
        </GcButton>
        <div className={`flex-1 text-center text-white`}>
          {this.state.value}
        </div>
        <GcButton
          className={`flex-0 px-6 bg-green-600 hover:bg-green-700 text-white`}
          onClick={this.handleIncrement}
        >
          +1
        </GcButton>
      </div>
    )
  }
}
