import React from "react"

const sizes = {
  s: "px-2 py-2",
  m: "px-4 py-4",
  l: "px-8 py-8",
}

const GcButton = ({ children, className = "", size, onClick = "" }) => {
  return (
    <button
      className={`
        ${sizes[size] || sizes["m"]}
        ${className}
        rounded text-white 
        `}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default GcButton
